<template>
  <div>
    <h1>权限设置</h1>
    <el-tree
      :data="permissions"
      show-checkbox
      default-expand-all
      node-key="id"
      ref="tree"
      highlight-current
      :props="defaultProps"
    >
    </el-tree>

    <div class="buttons">
      <el-button type="primary" @click="savePermission">保存</el-button>
      <el-button @click="resetChecked">清空</el-button>
    </div>
  </div>
</template>
<style>
.buttons {
  text-align: center;
  margin-top: 10px;
}
</style>
<script>
import RoleApi from '@/api/RoleApi';
import { navTo,queryParam } from "@/utils/NavigatorUtils";
  export default {
    created(){
      this.roleId = this.$route.params.roleId;
      RoleApi.queryAllPermissions().then((resp)=>{
          if(resp.code == "200"){
              this.permissions = resp.data;
              
          }
      }).then(()=>{
          RoleApi.queryPermissionsofMine().then((resp)=>{
              if(resp.code == "200"){
                  this.permissionOfMine = resp.data;
                  this.$refs.tree.setCheckedNodes(this.permissionOfMine)
              }
          })
      });
    },
    methods: {
      resetChecked() {
        this.$refs.tree.setCheckedKeys([]);
      },
      savePermission(){
        let checkedPemission = this.$refs.tree.getCheckedKeys()
        let data={
          "checkedPemission":checkedPemission,
          "role":this.roleId
          }
        RoleApi.savePermissions(data).then((resp)=>{
          if(resp.code == "200"){
             navTo(this,"/roleList")
             this.$successMsg(resp.msg)
          }else{
             this.$errorMsg(resp.msg)
          }
        })
      }
    },

    data() {
      return {
        permissions: [],
        permissionOfMine:[],
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        roleId:0
      };
    }
  };
</script>